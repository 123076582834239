export const theme = {
    colors: {
        //Colors
        lightGreen: "#475942",
        green: "#1B4001",
        beige: "#BFAE99",
        orange: "#F2B988",
        white: "#F2DDD5"
    },
    other: {

    },
};