import styled from "styled-components";

const S = {
    NavContainer: styled.div`
    width: 100%;
    position: fixed;
    z-index: 100;
    background: #475942;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        .brand{
            font-size: 1.1em;
        }

        .brand:hover{
            cursor: pointer;
        }
    
        .dark-theme{
            background: #475942;
            margin: 0 auto;
            color: white;
            width: 100%;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .dark-theme li{
            list-style-type: none;
            font-weight: 100;            
        }

        .dark-theme .nav-item a:hover{
            color: #d1d5d0;
            cursor: pointer;
        }

        .dark-theme .social-media{
            color: #acb4aa;
            cursor: pointer;
            margin-right: 1rem;
            text-align: center;
            font-size: 1.1em;

            @media only screen and (max-width: 992px) {
                display: none;
            }
        }
        
        dark-theme .social-media a:hover{
            color: #d1d5d0;
        }

        .dark-theme .nav-item a{
            color: #acb4aa;
            text-align: center;
        }
       
    `,
    BackToTop: styled.div`
        width: auto; 
        height: auto; 

        #btn-back-to-top{
            position: fixed;
            bottom: 7%;
            right: 0.5em;
            z-index: 100;
        }
    `,
    BouncingArrow: styled.div`
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    
      
    .bounce {
    margin: 2% 0;
    background: #198754;
    width: 1.1em;
    border-radius: 1em;
    color: white;
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
    padding: 0.3em;
    }

    .bounce:hover{
        opacity: 0.9;
        transition: 0.3s;
        background: #157347;
    }
    
    @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
    }
    
    `,
    Footer: styled.div`
        width: 100%; 
        height: 6%;
        background: #475942;
        position: fixed;
        bottom: 0;
        color: white;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
        display: none;
        -webkit-animation-name: cssAnimation;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
      }
      
      @keyframes cssAnimation {
        from {
          -webkit-transform: translateY(60px);
        }
        to {
            -webkit-transform: translateY(0px);
        }
      }

        .footer{
            background: white;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            color: black;
            margin: 0 auto;
        }
        .flex{
            display: flex;
        }
        .icon{ 
            width: 20%;
            margin-top: 1%;
            color: white;
            transition: transform: .2s;
        }
        .icon:hover{
            cursor: pointer;
            transform: scale(1.1);
        }

        a:hover{
            color: white;
        }
    `,
    CookieConsentContainer: styled.div`
    width: 98%;
    height: auto;
    left: 1%;
    bottom: 0;
    z-index: 1000;
    position: fixed; 
    background: rgba(250, 190, 88, 0.7);
    border-radius: 0.3rem 0.3rem 0 0;
    animation: slideIn 1s 1;

    @keyframes slideIn {
    from {bottom: -100%;}
    to {bottom: 0;}
    }

    .container {
        height: 100%;
        position: relative; 
        bottom: 0;

        a {
            font-weight: bold;
            color: black;
        }
    }

    .item {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: table;

        p{
            display: table-cell;
            vertical-align: middle;
        }
    }

    .closeButton{
        position: absolute;
        right: 1rem;
        top: 1rem;
        z-index: 1000;
    }
    `
};

export default S;