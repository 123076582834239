import React from 'react'
import { Button } from 'react-bootstrap';
import S from './styles';
import Imran from '../images/om-meg.jpg';
import ReactGA from 'react-ga';

const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);

    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

const OmOss = () => {
    return (
        <>
            <S.PageName>Om meg</S.PageName>
            <S.Content>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-8 height'>
                            <p>
                                Jeg er født på Lillehammer og oppvokst i Øyer. I min oppvekst drev jeg mye med aktiviteter og idrett. Det var også gjennom idretten at jeg for første gang fikk et innblikk i en terapeut sin hverdag, da jeg selv måtte gå til behandling for en skade. Interessen for faget vokste frem allerede da. Interessen fortsatte å vokse videre da jeg gikk Idrettslinjen ved Gausdal Videregående skole og deretter et årsstudium med Idrett ved Nord Universitet. Studiene fortsatte videre med en fulltidsutdanning innenfor osteopati ved Høyskolen Kristiania i Oslo. <br /> <br />
                                Jeg har alltid vært fascinert av kropp og kunnskap rundt den, som gjorde at årene på skolebenken var utrolig spennende. I tiden bak meg som osteopat har jeg lært mye om hvordan smerte og plager kan henge sammen med tidligere skader og opplevelser. Med flere års erfaring som osteopat har jeg lært mye av pasientene mine. Det er stadig vekk nye perspektiv som bygger opp under interessen og spenningen for å jobbe som en terapeut. <br /> <br /> Alle er virkelig et individ, samtidig som det er en del mønstre som ofte går igjen. Enten pasienten er av den eldre garde eller bare så vidt har tatt sine første steg, så vil jeg alltid være takknemlig hvis akkurat jeg får være din helse-detektiv. Jeg er stolt over faget mitt, elsker det jeg driver med og som autorisert helsepersonell kan du være sikker på at du møter en godt kvalifisert terapeut.
                            </p>
                        </div>
                        <div className='col-12 col-lg-4 parent'>
                            <div className="profile-card-4 text-center"><img src={Imran} className="img img-responsive" alt="Autorisert helsepersonell - Imran Bogfjelldal" />
                                <div className="profile-content">
                                    <div className="profile-name">Imran Bogfjelldal
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="profile-overview">
                                                <p>TELEFONNUMMER</p>
                                                <strong>+47 452 22 159</strong></div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="profile-overview">
                                                <p>EPOST</p>
                                                <strong>post@osteopatimran.no</strong></div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="profile-overview">
                                                <Button variant="success" target="_blank" href="https://hafjellosteopati.bestille.no/OnCust2/#!/" onClick={eventTrack.bind(this, "Timebooking", "Om meg - Book time her", "Button")}>Book time her</Button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </S.Content>
        </>
    )
}

export default OmOss