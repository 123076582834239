import React from 'react';
import S from './styles';
import ReactGA from 'react-ga';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);

    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

const Kontakt = () => {

    return (
        <>
            <S.PageName>Kontakt</S.PageName>
            <S.Content>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <iframe onClick={eventTrack.bind(this, "Kart", "Kart på kontaktsiden", "Link")} title="Kart" width="100%" height="100%" className="border" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Storgata%2089%20Lillehammer+(Osteopat%20Imran%20Bogfjelldal)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=6056f29664d22689ecbfa1ad84cb965428cdc955'></script>
                        </div>
                        <div className='col-12 col-md-6'>
                            <h3 className='center'>Osteopat Imran Bogfjelldal</h3>
                            <div className="row">
                                <div className='col-12 col-md-6 center'>
                                    <strong>Adresse</strong>
                                    <p>Storgata 89<br />
                                    2615 Lillehammer<br />
                                    Kronenbygget i 2. etg
                                    </p>
                                    <strong>Åpningstider</strong>
                                    <p>Mandag - fredag 08:00 - 19:00</p>

                                </div>
                                <div className='col-12 col-md-6 center'>
                                    <strong>E-post</strong>
                                    <p>post@osteopatimran.no</p>
                                    <strong>Telefon</strong>
                                    <p><a href="tel:004745222159">452 22 159</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </S.Content>
        </>
    )
}

export default Kontakt