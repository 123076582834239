import React from 'react';
import { Container } from 'react-bootstrap';
import S from '../styles/styles';
import SS from './styles';

const Personvernpolicy = () => {
  return (
    <>
        <Container>
            <S.Section id="personvern">
                <SS.PageName>Personvern</SS.PageName>
                <SS.PersonvernContent>
                    <h5>Loggfiler</h5>
                    <p>Som de fleste andre nettsteder bruker dette nettstedet loggfiler. Informasjonen i loggfilene inkluderer Ip-adresser, browser type, ISP, tidspunkter, refererende sider m.m. Denne informasjonen er ikke personlig identifiserbar.</p>
                    <h5>Cookies</h5>
                    <p>Nettstedet osteopatimran.no benytter cookies. <br />
                    Cookies er såkalte informasjonskapsler (små tekstfiler) som lagres på din enhet når du åpner en nettside. Informasjonskapslene lagrer informasjon om ditt besøk på nettstedet og lagrer ikke data som kan knyttes til deg som enkeltperson. <br />
                    Cookies kan være nyttig både for den som eier nettstedet og for den som besøker det. Eieren kan tilpasse nettsidene ut fra informasjonen de får gjennom cookies, for å gi en bedre og mer tilpasset brukeropplevelse. Statistikkcookies kan gi nettstedeieren informasjon om hvilke sider som blir besøkt og hvor lenge brukerne er inne på nettstedet. 
                    </p>
                    <h5>Du kan klage på vår behandling av personopplysninger</h5>
                    <p>Vi håper du vil si ifra dersom du mener vi ikke overholder reglene i personopplysningsloven. Si da gjerne først i fra gjennom den kontakten eller kanalen du allerede har etablert med oss. Du kan også klage over vår behandling av personopplysninger. Dette gjør du til Datatilsynet.</p>
                </SS.PersonvernContent>
            </S.Section>
        </Container>
    </>
  )
}

export default Personvernpolicy