import React from 'react'
import S from './../styles/styles'
import Hjem from '../pages/Hjem'
import Kontakt from '../pages/Kontakt'
import Osteopati from '../pages/Osteopati'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Priser from '../pages/Priser';
import OmOss from '../pages/OmOss';
import VanligePlager from '../pages/VanligePlager';
import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { Element } from 'react-scroll';

const MiddleSection = (props) => {

    const scrollIntoView = (view) => {
        let element = document.getElementById(view).offsetTop;
        const screenWidth = window.screen.width;

        window.scrollTo({ top: element - `${screenWidth > 768 ? 120 : 80}`, behavior: "smooth", block: "start", inline: "nearest" });
    }

    useEffect(() => {
        AOS.init();
        
        setTimeout(() => {
            if(props.view && props.view !== undefined) {
                scrollIntoView(props.view);
            }
        }, 750)
    }, [props])

    return (
        <>
            <Container>
                <Element name="hjem">
                    <S.Section
                        id="hjem"
                        data-aos="fade-right"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="400"
                    >
                        <Hjem />
                    </S.Section>
                </Element>
                <Element name="osteopati"  id="transparent-space">
                    <S.Section
                        id="osteopati"
                        data-aos="fade-left"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="400"
                    >
                        <Osteopati />
                    </S.Section>
                </Element>
                <Element name="vanlige-plager" id="transparent-space">
                    <S.Section
                        id="vanlige-plager"
                        data-aos="fade-right"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="400"
                    >
                        <VanligePlager />
                    </S.Section>
                </Element>
                <Element name="priser" id="transparent-space">
                    <S.Section
                        id="priser"
                        className="priser"
                        data-aos="fade-left"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="400"
                    >
                        <Priser />
                    </S.Section>
                </Element>
                <Element name="om-oss" id="transparent-space">
                    <S.Section
                        id="om-oss"
                        className="om-oss"
                        data-aos="fade-right"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="400">
                        <OmOss />
                    </S.Section>
                </Element>
                <Element name="kontakt" id="transparent-space">
                    <S.Section
                        id="kontakt"
                        className="kontakt"
                        data-aos="fade-left"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="400"
                        >
                        <Kontakt />
                    </S.Section>
                </Element>
            </Container>
        </>
    )
}

export default MiddleSection