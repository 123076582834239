import React from 'react';
import Image from '../images/slide-2-new.jpg';
import Slide1 from '../images/slide-4-new.jpg';
import Slide2 from '../images/slide-3-new.jpg';
import Slide3 from '../images/slide-5-new.jpg';
import { Carousel } from 'react-bootstrap';
import S from './styles';   

const Hjem = () => {
    return (
        <>
            <Carousel className="slideshow">
                <Carousel.Item interval={5000} >
                    <img
                        className='d-block w-100'
                        src={Image}
                        alt="Imran Bogfjelldal - Autorisert helsepersonell"
                    />
                    <Carousel.Caption>
                        <S.Carousel>
                            <S.ImageText>Imran Bogfjelldal</S.ImageText>
                            <h5>Autorisert helsepersonell</h5>
                        </S.Carousel>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000} >
                    <img
                        className='d-block w-100'
                        src={Slide1}
                        alt="Struktur, kvalitet og erfaring"
                    />
                    <Carousel.Caption>
                        <S.Carousel>
                            <h1>Struktur, kvalitet og erfaring</h1>
                        </S.Carousel>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000} >
                    <img
                        className='d-block w-100'
                        src={Slide2}
                        alt="Omsorg"
                    />
                    <Carousel.Caption>
                        <S.Carousel>
                            <h1>Omsorg</h1>
                        </S.Carousel>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={5000} >
                    <img
                        className='d-block w-100'
                        src={Slide3}
                        alt="Faglig tyngde"
                    />
                    <Carousel.Caption>
                        <S.Carousel>
                            <h1>Faglig tyngde</h1>
                        </S.Carousel>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </>
    )
}

export default Hjem