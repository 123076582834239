import React from 'react'
import S from './styles'
import CloseButton from 'react-bootstrap/CloseButton'
import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Container, Col, Row, ButtonGroup } from 'react-bootstrap';

const CookieConsent = ({ callback }) => {
    const [show, setShow] = useState(false);

    const handleCallBack = (consent) => {
        callback(consent);
    }

    const getCookie = (name) => {
        const value = " " + document.cookie;
        const parts = value.split(" " + name + "=");
        return parts.length < 2 ? undefined : parts.pop().split(";").shift();
    };
      
    const setCookie = function (name, value, expiryDays) {
    const exdate = new Date();
    exdate.setHours(
        exdate.getHours() +
        (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
    );

    document.cookie =
        name +
        "=" +
        value +
        ";expires=" +
        exdate.toUTCString()
    };

    const setConsent = (value) => {
        switch (value) {
            case "yes":
                setCookie("cookieConsent", "yes", 90);
                setShow(false);
            break;
            case "no":
                setCookie("cookieConsent", "no", 1);
                setShow(false);
            break;
            default: 
        }
    }

    const checkForConsent = useCallback(() => {
        const cookie = getCookie("cookieConsent");
        switch(cookie) {
            case "yes":
            setShow(false);
            handleCallBack("yes");
            break;
            case "no":
            setShow(false);
            handleCallBack("no");
            break;
            default: 
            setShow(true);
        }
    }, [handleCallBack])

    useEffect(() => {
            setTimeout(()=>{
                checkForConsent();
           }, 1000)
    },[checkForConsent, show]);

  return (
    <>
        { show && 
        <S.CookieConsentContainer>
            <CloseButton 
                className='closeButton'
                onClick={() => setShow(false)}
            />
           <Container>
                <Row className="col d-flex justify-content-center align-items-center p-2 py-3 ">
                    <Col md={12} lg={9}>
                        <p className="mb-0 pe-5 pe-md-5">Vi bryr oss om ditt personvern. Vår nettside benytter noen få informasjonskapsler (cookies) til analyse. Du kan lese mer om dette i vår <a href="/#/personvernpolicy">personvernpolicy</a></p>
                    </Col>
                    <Col md={12} lg="auto" className="justify-content-center mt-2 mt-md-2 mt-lg-0">
                        <div className="d-grid">
                            <ButtonGroup>
                                <Button onClick={() => setConsent("yes")} variant="success">
                                    Godkjenn
                                </Button>
                                <Button onClick={() => setConsent("no")} variant="light">
                                    Avslå
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Col>
                    {/*
                    <Col md={6} lg="auto" className="d-grid justify-content-center mt-md-2 mt-lg-0">
                        <Button onClick={() => setConsent("yes")} variant="success" size="lg">
                            Godkjenn
                        </Button>
                    </Col>
                    <Col md={6} lg="auto" className="d-flex justify-content-center">
                        <div className='d-grid gap-2'>
                            <Button onClick={() => setConsent("no")} variant="light" size="lg" >
                                Avslå
                            </Button>
                        </div>
                      </Col>*/}
                </Row>
            </Container>
            {/*
            <div className='container'>
                
                <Stack direction="horizontal" gap={3}>
                    <div className="me-auto item">
                        <p>Vi bryr oss om ditt personvern. Vår nettside benytter noen få informasjonskapsler (cookies) til analyse. Du kan lese mer om dette i vår <a href="/personvernpolicy">personvernpolicy</a></p>
                    </div>
                    <div className='item'>
                        <Button onClick={() => setConsent("yes")} variant="success">
                            Godkjenn
                        </Button>
                    </div>
                    <div className='item'>
                        <div className="vr" />
                    </div>
                    <div className='item'>
                        <Button onClick={() => setConsent("no")} variant="light">
                            Avslå
                        </Button>
                    </div>             
                </Stack>
            </div>
            */}
        </S.CookieConsentContainer>
        }
    </>
  )
}

export default CookieConsent