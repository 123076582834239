import React from 'react';
import S from './styles';
import Image from '../images/priser-1-new.jpg';
import { Button } from 'react-bootstrap';
import ReactGA from 'react-ga';

const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);

    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

const Priser = () => {
    return (
        <>
            <S.PageName>Prisliste</S.PageName>
            <S.Content>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-7 center height'>
                            <p>Førstegangskonsultasjon (60 min) 920,-</p>
                            <p>Oppfølgende konsultasjon (45 min) 820,-</p>
                            <p>Oppfølgende konsultasjon (30 min) 650,-</p>
                            <p>Studenter 20% rabatt.</p>
                            <p>Avbestillingsgebyr 700,-</p>
                            <p>Timen må avbestilles 24t i forveien for å unngå gebyr.</p>
                            <p>For timer som gjelder mandag må det avbestilles på fredag før kl 16.</p>
                            <Button variant="success" target="_blank" onClick={eventTrack.bind(this, "Timebooking", "Prisliste - Book time her", "Button")} href="https://hafjellosteopati.bestille.no/OnCust2/#!/">Book time her</Button>
                        </div>
                        <div className='col-12 col-lg-5 d-none d-lg-block parent'>
                            <S.Image src={Image} alt="Behandling av pasienter" />
                        </div>
                    </div>
                </div>
            </S.Content>
        </>
    )
}

export default Priser