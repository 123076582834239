import styled from "styled-components";

const S = {
    PageName: styled.h1`
        width: 100%;
        text-align: center;
        padding-bottom: 2rem;
        color: white;
    `,
    Carousel: styled.div`
        background-image: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 20%, rgba(255,255,255,0) );
        padding: 1em;
        border-radius: 0.2em;
        width: 100%;
        margin-bottom: 5%;

        h1{
            font-size: 4em;

            @media only screen and (max-width: 768px) {
                font-size: 3em;
            }

            @media only screen and (max-width: 600px) {
                font-size: 2em;
            }
        }

    `,
    ImageText: styled.h1`
        font-size: 4em;

        @media only screen and (max-width: 600px) {
            font-size: 2em;
      }
    `,
    Content: styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        color: white;
        padding-bottom: 5%;

        .center{
            text-align: center;
        }

        a{
            color: white;
            text-decoration: none;
            cursor: pointer;
        }

        .green{
            background: #475942;
            color: black;
        }
        .height{
            line-height: 2;
            padding-left: 5%;
            padding-right: 5%;
        }

        .border{
            padding: 10px;
            border-radius: 5px;
            background: white;
        }

        .profile-card-4 {
            max-width: 100%;
            border-radius: 5px;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            position: relative;
            margin: 10px auto;
            cursor: pointer;
            top: 45%;
            right: 5%;
            transform: translateY(-47%);
            margin-bottom: 1rem;

            @media only screen and (max-width: 768px) {
                max-width: 100%;
            }

            @media only screen and (max-width: 992px){
                position: relative;
                left: 0rem;
            }
        }
        
        .profile-card-4 img {
            width: 100%;
            transition: all 0.25s linear;
        }
        
        .profile-card-4 .profile-content {
            position: relative;
            padding: 15px;
            background: white;
        }
        
        .profile-card-4 .profile-name {
            font-weight: bold;
            position: absolute;
            left: 0px;
            right: 0px;
            top: -70px;
            color: #FFF;
            font-size: 17px;
        }
        
        .profile-card-4 .profile-name p {
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 1.5px;
        }
        
        .profile-card-4 .profile-description {
            color: black;
            font-size: 14px;
            padding: 10px;
        }
        
        .profile-card-4 .profile-overview {
            padding: 15px 0px;
        }
        
        .profile-card-4 .profile-overview p {
            font-size: 10px;
            font-weight: 600;
            color: black;
        }
        .profile-card-4 .profile-overview strong {
            font-size: 10px;
            font-weight: 600;
            color: black;
        }
        
        .profile-card-4 .profile-overview h4 {
            color: #273751;
            font-weight: bold;
        }
        
        .profile-card-4 .profile-content::before {
            content: "";
            position: absolute;
            height: 32px;
            top: -16px;
            left: 0px;
            right: 0px;
            background: white;
            z-index: 0;
            transform: skewY(3deg);
        }
        
        .profile-card-4:hover img {
            transform: rotate(5deg) scale(1.1, 1.1);
            filter: brightness(110%);
        }

        .col-md-3{
            margin-top: 1em;
        }

        ul{
            @media only screen and (max-width: 992px){
               padding-left: 1rem;
               padding-right: 1rem;
            }
        }
    `,

    Image: styled.img`
        width:100%;
        height: auto;
        object-fit: contain;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 0.3rem;
        transition: all 0.25s linear;
        right: 5%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &&[src*="png"] {
            box-shadow: none;
        }

        @media only screen and (max-width: 992px){
            width: 80%;
            left: 10%;
            position: relative;
        }
    `,
    PersonvernContent: styled.div`
        width: 100%;
        height: 100%;
        padding: 0 3rem 3rem 3rem;
        text-align: center;
        line-height: 2;
        
        p {
            color: white;
            font-weight: 200;
        }

        h5 {
            color: white;
            font-weight: 400;
            margin-top: 1rem;
        }
    `,
};

export default S; 