import React from 'react';
import S from './styles';
import Image from '../images/vitruvian-man.png';

const VanligePlager = () => {
    return (
        <>
            <S.PageName>Vanlige plager</S.PageName>
            <S.Content>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-12 col-lg-7 height'>
                            <p className="center">En osteopat oppsøkes ofte ved følgende smerter og plager i muskel- og skjelettsystemet:</p>
                            <ul>
                                <li>Ryggsmerter</li>
                                <li>Korsryggsmerter</li>
                                <li>Brystsmerter</li>
                                <li>Nakkesmerter</li>
                                <li>Smerte i skulder</li>
                                <li>Hoftesmerter</li>
                                <li>Smerte i knær</li>
                                <li>Smerte i ankel og fot</li>
                                <li>Bekkensmerter og andre plager i forbindelse med svangerskap</li>
                                <li>Akutte- og belastningsrelaterte plager</li>
                                <li>Idrettsskader</li>
                                <li>Hodepine, svimmelhet og balanseproblemer</li>
                                <li>Migrene</li>
                                <li>GERD</li>
                                <li>IBS og fordøyelsesproblematikk</li>
                            </ul>
                        </div>
                        <div className='col-12 col-md-12 col-lg-5 d-md-block parent'>
                            <S.Image src={Image} alt="Behandling av pasienter" />
                        </div>
                    </div>
                </div>
            </S.Content>
        </>
    )
}

export default VanligePlager