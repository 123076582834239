import React from 'react';
import S from './styles';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';
import { useState } from 'react';
import { useEffect } from 'react';

const Footer = (props) => {
    let location = window.location.pathname;
    const [shouldScroll, setShouldScroll] = useState(false);
    const [userHasScrolled, setUserHasScrolled] = useState(false);
    const [screenWidth, setScreenWidth] = useState(-120);

    window.addEventListener("scroll", () => {
        setUserHasScrolled(true);
    });

    window.addEventListener("resize", () => {
        setScreenWidth(window.screen.width);
    })

    const handleNextSection = () => {
        const navLinks = document.getElementsByClassName("nav-link active");
        let link = navLinks[0].innerHTML;

        switch (true) {
            case link === "Hjem":
                let scrollDiv1 = document.getElementById("osteopati").offsetTop;
                window.scrollTo({ top: scrollDiv1 - `${screenWidth > 768 ? 120 : 80}`, behavior: 'smooth' });
                break;
            case link === "Osteopati":
                let scrollDiv2 = document.getElementById("vanlige-plager").offsetTop;
                window.scrollTo({ top: scrollDiv2 - `${screenWidth > 768 ? 120 : 80}`, behavior: 'smooth' });
                break;
            case link === "Vanlige plager":
                let scrollDiv3 = document.getElementById("priser").offsetTop;
                window.scrollTo({ top: scrollDiv3 - `${screenWidth > 768 ? 120 : 80}`, behavior: 'smooth' });
                break;
            case link === "Priser":
                let scrollDiv4 = document.getElementById("om-oss").offsetTop;
                window.scrollTo({ top: scrollDiv4 - `${screenWidth > 768 ? 120 : 80}`, behavior: 'smooth' });
                break;
            case link === "Om meg":
                let scrollDiv5 = document.getElementById("kontakt").offsetTop;
                window.scrollTo({ top: scrollDiv5 - `${screenWidth > 768 ? 120 : 80}`, behavior: 'smooth' });
                break;
            case link === "Kontakt":
                let scrollDiv6 = document.getElementById("hjem").offsetTop;
                window.scrollTo({ top: scrollDiv6 - `${screenWidth > 768 ? 120 : 80}`, behavior: 'smooth' });
                break;
            default: 
            break;
        }
    }

    const eventTrack = (category, action, label) => {
        console.log("GA event:", category, ":", action, ":", label);

        ReactGA.event({
            category: category,
            action: action,
            label: label,
        })
    }


    useEffect( () => {
        if (props.consent !== "undefined") {
            if (location !== "/") {
                setShouldScroll(false);
            } else if (userHasScrolled) {
                setTimeout( () => {
                    setShouldScroll(true);
                }, 500);
            }
        }
    }, [location, props, userHasScrolled])    

    return (
        <>
            {shouldScroll && 
                <S.BouncingArrow id="bouncingArrow" >
                <FontAwesomeIcon icon={faArrowDown} size="2x" title="Vis neste seksjon" alt="Vis neste seksjon" className="bounce" onClick={() => { eventTrack.call(this, "Buttons", "Bouncing button, Footer", "Button"); /*handleShowMore();*/ handleNextSection(); }} />
                </S.BouncingArrow>
            }
        </>
    )
}

export default Footer