import React from 'react';
import S from './styles';
import Image from '../images/osteopati-1-new.jpg';
import 'aos/dist/aos.css';

const Osteopati = () => {
    return (
        <>
            <S.PageName>Osteopati</S.PageName>
            <S.Content>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-8 height'>
                            <h3 className='center'>"Osteopaten er opptatt av sammenhengen".</h3><br />
                            <p>
                                Som osteopat er jeg interessert i å finne sammenhengen mellom hvordan hverdagen er for pasienten og plagene på kroppen. Man vil bli tatt godt imot, og hver pasient vil få utarbeidet en individualisert behandlingsplan. En osteopat vil med bakgrunn i vitenskapelige fag som anatomi, fysiologi og nevrologi undersøke og behandle pasienten. <br /> <br />  Undersøkelsen kan bestå av medisinske og ortopediske tester som vil gi meg som helsepersonell en bedre oversikt over vedkommende sin helse. Under selve behandlingen vil jeg bruke hendene mine som hovedverktøy. Man får en blanding av massasje, trykkpunkter, strekking/tøying og «knekk». <br /> <br /> All behandling skjer i samarbeid med pasienten, så om man ønsker å avstå fra deler av behandlingen som for eksempel «knekk», så finner jeg andre metoder for å hjelpe. Behandlingen trenger heller ikke å være smertefull, en kan be om kun avslappende behandling. <br /> <br />Det er viktig for meg å få et så helhetlig bilde av pasienten som mulig, derfor brukes det god tid på å kartlegge deres helse. I tilfeller hvor jeg som osteopat finner andre årsaker til plager – som f. eks sykdom, henvises du videre til riktig spesialist.
                                <br /> <br /> Alle er velkomne, uansett alder.
                            </p>
                        </div>
                        <div className='col-12 col-lg-4 d-none d-lg-block parent'>
                            <S.Image src={Image} alt="Osteopaten er opptatt av sammenhengen" />
                        </div>
                    </div>
                </div>

            </S.Content>
        </>
    )
}

export default Osteopati