import S from './styles/styles';
import { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import CookieConsent from './components/CookieConsent';
import MiddleSection from './components/MiddleSection';
import Personvernpolicy from './pages/Personvernpolicy';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import { useEffect, useState, useCallback } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { scrollSpy } from 'react-scroll';

const App = () => {
  const colors = ["#1B4001", "#BFAE99", "#F2B988", "#FCBC0F", "#F2DDD5"];
  const numBalls = 30;
  const balls = [];
  const [consent, setConsent] = useState("undefined");
  const TRACKING_ID = "UA-236816357-1";
  const [view, setView] = useState();

  const initializeAnalytics = useCallback( () => {
    if ( consent !== "undefined" ) {
      switch (consent) {
        case "yes":
          ReactGA.initialize(TRACKING_ID);
        break;
        case "no":
          ReactGA.initialize(TRACKING_ID, {
            gaOptions: {
              storage: 'none',
              storeGac: false
            }
          });
        break;
        default:
      }
    }
  })

  const callback = payload => {
    setConsent(payload);
  }

  for (let i = 0; i < numBalls; i++) {
    let ball = document.createElement("div");
    ball.classList.add("ball");
    ball.style.background = colors[Math.floor(Math.random() * colors.length)];
    ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
    ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
    ball.style.transform = `scale(${Math.random()})`;
    ball.style.width = `${Math.random()}em`;
    ball.style.height = ball.style.width;

    balls.push(ball);
    document.body.append(ball);
  }

  // Keyframes
  balls.forEach((el, i, ra) => {
    let to = {
      x: Math.random() * (i % 2 === 0 ? -11 : 11),
      y: Math.random() * 12
    };

     el.animate(
      [
        { transform: "translate(0, 0)" },
        { transform: `translate(${to.x}rem, ${to.y}rem)` }
      ],
      {
        duration: (Math.random() + 1) * 2000, // random duration
        direction: "alternate",
        fill: "both",
        iterations: Infinity,
        easing: "ease-in-out"
      }
    );
  });

  useEffect(() => {
    document.title = 'Osteopat Imran Bogfjelldal';
    initializeAnalytics();
  }, [consent, initializeAnalytics]);

  return (
    <ThemeProvider theme={S.theme}>
      <HashRouter>
      <Header setView={setView}/>
      <S.App>
        <Routes>
          <Route path="/" element={<MiddleSection view={view} />} />
          <Route path="/personvernpolicy" element={<Personvernpolicy />} />
        </Routes>
      </S.App>
      <CookieConsent callback={callback}/>
      <Footer consent={consent} />
      </HashRouter>
      {/* <BrowserRouter>
      <Header setView={setView}/>
      <S.App>
        <Routes>
          <Route path="/" element={<MiddleSection view={view} />} />
          <Route path="/personvernpolicy" element={<Personvernpolicy />} />
        </Routes>
      </S.App>
      <CookieConsent callback={callback}/>
      <Footer consent={consent} />
      </BrowserRouter> */}
    </ThemeProvider>
  );

};

export default App;
