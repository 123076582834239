import styled from "styled-components";
import { theme } from "./themeStyles";

const S = {
    theme: theme,

    App: styled.div`
    width: 100%;
    overflow-x: hidden;
    
    #kontakt{
        margin-bottom: 40%;
    }

    #hjem{
        background: rgba(0,0,0, 0);
        box-shadow: none;

        @media only screen and (max-width: 768px) {
        width: 98vw;
        position: relative;
        left: calc(-49vw + 50%);
        }

        img{
            width: 100%;
        }

        .slideshow {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
    }

    #osteopati, #priser, #om-oss, #kontakt, #vanlige-plager{
        padding: 1rem;
    }

    #transparent-space {
        padding-top: 5rem;

        @media only screen and (max-width: 768px){
            padding-top: 2rem;
        }
    }
       
    `,

    Section: styled.div`
        width: 100%; 
        height: auto;
        text-align: left;
        background: rgba(71, 89, 66, 1);
        font-weight: 100;
        padding: 0rem;
        border-radius: 0.3rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      
        &#hjem {
            padding-top: 5rem;
        }

        &#personvern {
            margin-top: 5rem;
        }

        h1{
            font-weight: 300;
            font-size: 3rem;
        }

        img{
            border-radius: 0.3rem;
           
        }

        .caption{
            background: rgba(0, 0, 0, 0.2);
            border-radius: 0.3rem;
            padding: 1rem;
            margin-bottom: 15%;
        }

        .parent{
            position: relative;
        }
     
        ul{
            columns: 2;  
        }
        
    `,

};

export default S;