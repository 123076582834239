import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import S from './styles';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const Header = ({setView}) => {
    const [screenWidth, setScreenWidth] = useState(-120);
    const [showButton, setShowButton] = useState(false);
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const eventTrack = (category, action, label) => {
        console.log("GA event:", category, ":", action, ":", label);

        ReactGA.event({
            category: category,
            action: action,
            label: label,
        })
    }

    const handleClick = (e) => {
        let link = e.target.name;
        let lowercaseLink = link.toLowerCase();
        let path = window.location.hash;

        if (path.length > 1) {
            setView(lowercaseLink);
            navigate('/');
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
        window.addEventListener("resize", () => {
            setScreenWidth(window.screen.width);
        })
    }, []);
    return (
        <>
            <S.NavContainer>
                <Navbar collapseOnSelect expand="lg" className="dark-theme" variant="dark" >
                    <Container>
                        <Navbar.Brand className="brand"><a onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>Osteopat Imran Bogfjelldal</a></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={eventTrack.bind(this, "Navigasjonsbar", "Meny for mindre skjermer", "Button")} />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto d-flex">
                                <ul className="navbar-nav">
                                    <li className='nav-item'><Link activeClass="active" offset={-80} spy={true} to="hjem" className="nav-link" name="hjem" onClick={ (e) => { eventTrack.bind(this, "Navigasjonsbar", "Hjem", "Link"); handleClick(e) }}>Hjem</Link></li>
                                    <li className='nav-item'><Link spy to="osteopati" offset={screenWidth > 768 ? -80 : -60} className="nav-link" name="osteopati" onClick={ (e) => { eventTrack.bind(this, "Navigasjonsbar", "Osteopati", "Link"); handleClick(e) }}>Osteopati</Link></li>
                                    <li className='nav-item'><Link spy to="vanlige-plager" offset={screenWidth > 768 ? -80 : -60} className="nav-link" name="vanlige-plager" onClick={ (e) => { eventTrack.bind(this, "Navigasjonsbar", "Vanlige plager", "Link"); handleClick(e) }}>Vanlige plager</Link></li>
                                    <li className='nav-item'><Link spy to="priser" offset={screenWidth > 768 ? -80 : -60} className="nav-link" name="priser" onClick={ (e) => { eventTrack.bind(this, "Navigasjonsbar", "Priser", "Link"); handleClick(e) }}>Priser</Link></li>
                                    <li className='nav-item'><Link spy to="om-oss" offset={screenWidth > 768 ? -80 : -60} className="nav-link" name="om-oss" onClick={ (e) => { eventTrack.bind(this, "Navigasjonsbar", "Om meg", "Link"); handleClick(e) }}>Om meg</Link></li>
                                    <li className='nav-item'><Link spy to="kontakt" offset={screenWidth > 768 ? -80 : -60} className="nav-link" name="kontakt" onClick={ (e) => { eventTrack.bind(this, "Navigasjonsbar", "Kontakt", "Link"); handleClick(e) }}>Kontakt</Link></li>
                                </ul>
                            </Nav>
                            <Nav>
                                <a className="nav-item social-media" href="https://www.facebook.com/osteopatimranbogfjelldal" title="Facebook" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} className="icon" alt="Facebook" size="2x" /></a>
                                <Button variant="success" target="_blank" href="https://hafjellosteopati.bestille.no/OnCust2/#!/" onClick={eventTrack.bind(this, "Timebooking", "Navigasjonsbar - Book time her", "Button")}>Book time her</Button>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </S.NavContainer>
            <S.BackToTop>
                {showButton && (
                    <Button onClick={() => { eventTrack.call(this, "Buttons", "Scroll to top, til høyre", "Button"); scrollToTop(); }} variant="success btn-floating btn-lg" id="btn-back-to-top">
                        <FontAwesomeIcon icon={faArrowUp} alt="Scroll til top" />
                    </Button>
                )}

            </S.BackToTop>
        </>
    )

}

export default Header